import React from 'react';


const Resume = ()=>{
    return(
        <div>
            <div  data-aos="fade-up"
                 className="max-w-7xl px-4 mx-auto py-12 border-l border-r border-dashed border-gray-700 aos-init aos-animate">
                <div
                     className="shadow-md max-w-5xl mx-auto overflow-hidden rounded-lg divide-gray-800">
                    <div  className="bg-gray-900 py-3 px-6 flex justify-between items-center"><h1
                         className="text-lg flex flex-row font-semibold text-gray-200"><span
                         className="pr-1">Resume</span>
                        - <span  className="pl-1 hidden md:block">Pratik Daigavane</span></h1> <a
                         target="_blank" rel="noreferrer" href="https://cdn.pratikd.in/resume-may-21.pdf"
                        download="Pratik-Daigavane-Resume.pdf" className="inline-flex text-indigo-500 hover:text-indigo-600 items-center px-2 py-1 border
               border-transparent shadow-sm text-sm font-medium
               rounded-md btn-color-style">
                        <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                             fill="currentColor" className="-ml-1 mr-3 h-5 w-5">
                            <path  fillRule="evenodd"
                                  d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                  clipRule="evenodd"></path>
                        </svg>
                        download
                    </a></div>
                    <div className="h-full" style={{height: "150vh"}}>
                        <embed src="https://cdn.pratikd.in/Pratik_Daigavane_Resume_Sept_2023.pdf#toolbar=0&navpanes=0&scrollbar=0" frameBorder="0" height="100%" width="120%" className="w-full h-full"/>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Resume